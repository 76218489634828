
import { sendResetPasswordEmail } from "@/api/auth";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MButton,
    MTextField
  }
})
export default class SettingPassword extends Vue {
  email = "";
  enteredEmail = false;

  get validEmail(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }

  get allEntered(): boolean {
    return this.enteredEmail;
  }

  allEnter() {
    this.enteredEmail = true;
  }

  async resetPassword() {
    this.allEnter();

    if (!this.validEmail) {
      return;
    }

    store.commit("START_LOADING", "メール送信中...");

    try {
      await sendResetPasswordEmail(this.email);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`パスワード再設定用のメール送信に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Faield to send reset password mail"
      );
      return;
    }

    alert(
      "パスワード再設定用のメールを送信しました。メールを確認してみてください"
    );
  }
}
